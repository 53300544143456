export const environment = {
  nombre_aplicacion : "Rac Inspecciones",
  _ssp: 'M#0AygZi36C9*EcPxNATUsB^V9WlZmUQJ^FrrJ$tUvvBQAnnftWS@DKfhmcrQ!*z',
  api: {
    "ull_": "https://testing.rutayasistenciaclub.cl/app/views/mw_autoinspeccion/",//"https://ws.rutayasistenciaclub.cl/app/views/mw_autoinspeccion/", // pruebas migraciones
    "uss_": 'U2FsdGVkX194OQ6Fhb7bFqabdNcyPXViWJLrJ8mBs0k=',
    "pss_": 'U2FsdGVkX19QcQIhfqibUohxb0wgk2k3cztQWMyRfPI=',
  },
  production: false,
  gtag_mode: true,
  // codigo para desarrollo
  gtagcode: 'GTM-P9GL26H',
  apiurl: "https://testing.rutayasistenciaclub.cl/app/views/mw_autoinspeccion/",//'https://ws.rutayasistenciaclub.cl/app/views/mw_autoinspeccion/',
  
  contacto: {
    wsp: "+56950104970",
    texto : "Hola! Necesito ayuda",

    whatsapp: {
      numero: "56950104970",
      mensaje: "Hola, necesito ayuda"
    },
    telef: "",
    email : ""
  }
};